export enum NDColors {
	INFO = "#5bc0de",
	DANGER = "#F1453D",
	PRIMARY = "#7B61FF",
	ERROR = "#E2483D",
	SECONDARY = "#df665a",
	SUCCESS = "#39ca74",
	WARNING = "#ff9800",
	DEFAULT = "#efeff4",
	DEFAULT_TEXT = "#404040",
	INFO_BACKGROUND = "#E3EDFF",
	INFO_LOGIN = "#FEF6F6",
	GREY = "#909090",

	WHITE = "#FFF",

	TEAL1 = "#436374",

	// Background
	GRADIENT_BACKGROUND = "linear-gradient(180deg, #FEF6F5 26.21%, #F5F2FF 100%)",
	DEFAULT_BACKGROUND = "#eee",

	// higher number, lighter color
	MAIN = "#007DFF",
	MAIN2 = "#2E94FF",
	MAIN3 = "#5CACFF",
	MAIN4 = "#8AC3FF",
	MAIN5 = "#B8DBFF",
	MAIN6 = "#E6F2FF",

	// Dark
	// higher number, lighter color
	DARK1 = "#212121",
	DARK2 = "#565656",
	DARK3 = "#7D7D7D",
	DARK4 = "#9B9B9B",
	DARK5 = "#B1B1B1",
	DARK6 = "#cecece",
	DARK7 = "#EBEBEB",
	DARK8 = "#F5F5F5",

	LIGHT_PURPLE = "#DDE3FF",
	PURPLE = "#7B61FF",
	LIGHT_GREEN = "#70C894",

	// Button
	LOGIN_ENABLED = "#BEC7FD",
	LOGIN_DISABLED = "#E8EBFC",
	LOGIN_HOVERED = "#D7DDFF",
	TEXT_DISABLED = "#00000061",
	SLATE = "#436374",

	LIGHT_SILVER = "#d8d8d8",
	LIGHT_BLACK = "#302F38",
	BLACK = "#000",
	GREY_LIGHT = "#9e9e9e",
	LIGHT_GREY = "#f4f4f9",
	DARK_GREY = "#6E6F81",
	FRESH_GREY = "#FAFAFF",
	DARK = "#2F3344",
	FIGMA_GREY = "#D0D0DF",
	FIGMA_PEACH = "#FDEDE6",
	GREEN = "#36B37E",
	RED = "#FF505F",
	CALENDAR_BLUE = "#3474E0",
	ONYX = "#393939",
	TINT = "#2E69FF",
	OFF_WHITE = "#F9F9FA",
	ERROR_DARK = "#e31b0c",
	YELLOW_LIGHT = "#fef3df",
	ORANGE_DARK = "#f90",

	BLUE_HIGHLIGHT = "#EAF0FF",
	RED_HIGHLIGHT = "#FFF9F2",
	LANGUID_LAVENDER = "#D0D0DF",
	SKY_DARK = "#E8EBFC",
	BLURPLE = "#7471F0",
	PASTEL_LILAC = "#EDECFF",
	// Video call components color
	RED_CALL_CONTROL = "#f44336",
	GREY_CALL_WARNING = "#616161",

	// GraphV2 color
	ORANGE = "#FF8200",
	ROYAL_BLUE = "#005FCC",
	FRENCH_VIOLET = "#8E06CD",
	BABY_PINK = "#FFACC6",
	VIVID_HARLEQUIN = "#02cf06",
	RED_PEPPER = "#B20725",
	AQUA_BLUE = "#00E5F8",
	ELF_GREEN = "#076351",
	CARMINE = "#DE0D2E",
	FUCHSIA = "#ED0DFD",

	//text color
	LOGIN_TEXT = "#414D81",
	LILAC = "#EDECFF",
	LIGHT_LILAC = "#C2C1FF",
	SECONDARY_LIGHT = "#FF737F",
}
